
import { defineComponent } from "vue";
import useTenant from "@/composables/useTenant";
import { useStore } from "vuex";
// import ChangePasswordModal from "@/components/modals/forms/ChangePasswordModal.vue";
export default defineComponent({
  name: "kt-user-menu-drawer",
  components: {
    // ChangePasswordModal,
  },

  setup() {
    const store = useStore();
    const user = store.getters.currentUser;
    const talent = useTenant().fetchTenant()?.tenancyName;

    const logout = () => {
      localStorage.removeItem("id_recovery_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("retail_admin");
      location.href = "/account/login";
    };

    return {
      logout,
      user,
      talent,
    };
  },
});
