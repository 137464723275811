import { APP_BASE_URL, axiosClient } from "@/core/services/AxiosClient";
import {
  ChangePasswordInput,
  ProfileServiceProxy,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
interface UsableSettings {
  changePassword: (details: ChangePasswordInput) => Promise<boolean>;
}

const useSettings = (): UsableSettings => {
  const store = useStore();

  const client = new ProfileServiceProxy(APP_BASE_URL, axiosClient);

  const changePassword = async (details: ChangePasswordInput) => {
    const resp = await client
      .changePassword(details)
      .then(() => {
        return true;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);

        return false;
      });

    return resp;
  };

  return {
    changePassword,
  };
};

export default useSettings;
