const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-graph-up",
        name: "Pages",
        locale: "Dashboard",
      },
      {
        heading: "customers",
        route: "/customers",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person-circle",
        name: "Pages.Customers",
        locale: "Customers",
      },
      {
        heading: "Territories",
        route: "/customers/territories",
        svgIcon: "/media/icons/duotune/finance/fin008.svg",
        fontIcon: "bi-geo-alt",
        name: "Pages.Territories",
        locale: "Territories",
      },
      {
        sectionTitle: "Products",
        route: "/products",
        svgIcon: "/media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-briefcase",
        name: "Pages.Products",
        locale: "Products",
        sub: [
          {
            heading: "Products",
            svgIcon: "/media/icons/duotune/finance/fin006.svg",
            fontIcon: "bi-briefcase",
            route: "/products",
            name: "Pages.Products",
            locale: "Products",
          },
          {
            heading: "Product units",
            svgIcon: "/media/icons/duotune/finance/fin007.svg",
            fontIcon: "bi-briefcase",
            route: "/products/product-units",
            name: "Pages.ProductUnits",
            locale: "ProductUnits",
          },
          {
            heading: "Product categories",
            svgIcon: "/media/icons/duotune/finance/fin008.svg",
            fontIcon: "bi-briefcase",
            route: "/products/product-categories",
            name: "Pages.ProductCategories",
            locale: "ProductCategories",
          },
          {
            heading: "Price list",
            svgIcon: "/media/icons/duotune/general/gen005.svg",
            fontIcon: "bi-briefcase",
            route: "/price-lists",
            // name: "Pages.Products",
            locale: "PriceLists",
          },
          {
            heading: "Product Bundles",
            svgIcon: "/media/icons/duotune/general/gen006.svg",
            fontIcon: "bi-briefcase",
            route: "/product-bundles",
            name: "Pages.ProductBundles",
            locale: "ProductBundles",
          },
        ],
      },
      {
        heading: "Orders",
        route: "/orders",
        svgIcon: "/media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-briefcase",
        name: "Pages.Orders",
        locale: "Orders",
      },
      {
        heading: "Agents",
        route: "/agents",
        svgIcon: "/media/icons/duotune/general/gen002.svg",
        fontIcon: "bi-briefcase",
        name: "Pages.Agents",
        locale: "Agents",
      },
      {
        sectionTitle: "Campaigns",
        route: "/campaigns",
        svgIcon: "/media/icons/duotune/general/gen010.svg",
        fontIcon: "bi-briefcase",
        name: "Pages.Campaigns",
        locale: "Campaigns",
        sub: [
          {
            heading: "Campaigns",
            route: "/campaigns",
            svgIcon: "/media/icons/duotune/general/gen010.svg",
            fontIcon: "bi-briefcase",
            name: "Pages.Campaigns",
            locale: "Campaigns",
          },
          {
            heading: "Campaign Rewards",
            route: "/campaign-rewards",
            svgIcon: "/media/icons/duotune/general/gen011.svg",
            fontIcon: "bi-briefcase",
            locale: "CampaignRewards",
          },
        ],
      },
      {
        sectionTitle: "Loyalty",
        route: "/loyalty",
        svgIcon: "/media/icons/duotune/general/gen015.svg",
        fontIcon: "bi-briefcase",
        locale: "Loyalty",
        sub: [
          {
            heading: "Loyalty Rewards",
            svgIcon: "/media/icons/duotune/general/gen015.svg",
            fontIcon: "bi-briefcase",
            route: "/loyalty",
            locale: "LoyaltyRewards",
          },
          {
            heading: "Loyalty Transactions",
            svgIcon: "/media/icons/duotune/general/gen016.svg",
            fontIcon: "bi-briefcase",
            route: "/loyalty",
            locale: "LoyaltyTransactions",
          },
        ],
      },
      {
        sectionTitle: "Discounts",
        route: "/discounts",
        svgIcon: "/media/icons/duotune/general/gen016.svg",
        fontIcon: "bi-briefcase",
        locale: "Discounts",
        sub: [
          {
            heading: "Discounts",
            svgIcon: "/media/icons/duotune/general/gen016.svg",
            fontIcon: "bi-briefcase",
            route: "/discounts",
            locale: "Discounts",
          },
        ],
      },
      {
        sectionTitle: "Call Requests",
        route: "/call-requests",
        svgIcon: "/media/icons/duotune/general/gen020.svg",
        fontIcon: "bi-briefcase",
        name: "Pages.CallRequests",
        locale: "CallRequests",
        sub: [
          {
            heading: "Call Requests",
            svgIcon: "/media/icons/duotune/general/gen020.svg",
            fontIcon: "bi-briefcase",
            route: "/callRequests",
            name: "Pages.CallRequests",
            locale: "CallRequests",
          },
          {
            heading: "Call Request History",
            svgIcon: "/media/icons/duotune/general/gen022.svg",
            fontIcon: "bi-briefcase",
            route: "/callRequests/history",
            name: "Pages.CallRequests.History",
            locale: "CallRequestsHistory",
          },
        ],
      },
      {
        heading: "Journey Plan",
        route: "/journey-plan",
        svgIcon: "/media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-briefcase",
        locale: "JourneyPlan",
        name: "Pages.Products",
      },
      {
        sectionTitle: "Administration",
        route: "/administration",
        svgIcon: "/media/icons/duotune/communication/com001.svg",
        fontIcon: "bi-briefcase",
        name: "Pages.Administration",
        locale: "Administration",
        sub: [
          {
            heading: "User",
            svgIcon: "/media/icons/duotune/communication/com006.svg",
            fontIcon: "bi-briefcase",
            route: "/admin/users",
            name: "Pages.Administration.Users",
            locale: "AdministrationUsers",
          },
          /*
            {
              heading: "Organization Units",
              svgIcon: "/media/icons/duotune/communication/com002.svg",
              route: "/organization-units",
            },
            {
              heading: "Roles",
              svgIcon: "/media/icons/duotune/communication/com003.svg",
              route: "/roles",
            },

            {
              heading: "Languages",
              svgIcon: "/media/icons/duotune/communication/com007.svg",
              route: "/languages",
            },
            {
              heading: "audit-logs",
              svgIcon: "/media/icons/duotune/communication/com008.svg",
              route: "/audit-logs",
            },
            {
              heading: "Subscription",
              svgIcon: "/media/icons/duotune/communication/com009.svg",
              route: "/subscription",
            },
            {
              heading: "Visual Settings",
              svgIcon: "/media/icons/duotune/communication/com010.svg",
              route: "/visual-settings",
            },
            {
              heading: "Webhook Subscriptions",
              svgIcon: "/media/icons/duotune/communication/com011.svg",
              route: "/webhook-subscriptions",
            },
            {
              heading: "Dynamic Properties",
              svgIcon: "/media/icons/duotune/communication/com012.svg",
              route: "/dynamic-properties",
            },
            {
              heading: "Settings",
              svgIcon: "/media/icons/duotune/communication/com013.svg",
              route: "/settings",
            },
          */
        ],
      },
    ],
  },
];

export default DocMenuConfig;
