
import { defineComponent, onMounted, ref } from "vue";
// import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    // const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const scrollElRef = ref<null | HTMLElement>(null);
    if (!store.getters.isUserAuthenticated) {
      window.location.href = "/account/login";
    }

    store.commit(Mutations.GET_CONFIG);
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;
    const locale = config.result.localization.values.RetailDemo;
    // console.log(config);
    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    // const translate = (text) => {
    //   if (te(text)) {
    //     return t(text);
    //   } else {
    //     return text;
    //   }
    // };
    const translate = (text) => {
      if (locale[text]) {
        return locale[text];
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      permissions,
      locale,
    };
  },
});
