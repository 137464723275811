
import { defineComponent } from "vue";
import { useStore } from "vuex";
import useTenant from "@/composables/useTenant";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTLocalizationMenu from "@/layout/header/partials/Localization.vue";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTLocalizationMenu,
  },
  setup() {
    const store = useStore();
    const user = store.getters.currentUser;
    const talent = useTenant().fetchTenant()?.tenancyName;
    return {
      user,
      talent,
    };
  },
});
