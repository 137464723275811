
  import { defineComponent, ref } from "vue";
  import { hideModal } from "@/core/helpers/dom";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { ChangePasswordInput } from "@/shared/service-proxies/generated-proxies";
  import useSettings from "@/composables/useSettings";

  export default defineComponent({
    name: "password-modal",
    setup() {
      const formRef = ref<null | HTMLFormElement>(null);
      const passwordModalRef = ref<null | HTMLElement>(null);
      const { changePassword } = useSettings();
      const loading = ref<boolean>(false);

      const defaultFormData = ref({
        currentPassword: "",
        newPassword: "",
      }) as unknown as ChangePasswordInput;
      const confirmPassword = ref("");
      let formData = ref<ChangePasswordInput>(defaultFormData);

      const rules = ref({
        currentPassword: [
          {
            required: true,
            message: "Current password is required",
            trigger: "change",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "New password is required",
            trigger: "change",
            oneOf: "",
          },
        ],
      });

      const submit = () => {
        if (!formRef.value) {
          return;
        }

        if (confirmPassword.value !== formData.value.newPassword) {
          Swal.fire({
            text: "New password and confirm password does not match",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }

        formRef.value.validate(async (valid) => {
          if (valid) {
            loading.value = true;
            const response = (await changePassword(formData.value)) as boolean;
            loading.value = false;

            if (response) {
              Swal.fire({
                text: "Password changed successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Okay!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(passwordModalRef.value);
                formData.value.currentPassword = "";
                formData.value.newPassword = "";
                confirmPassword.value = "";
              });
            } else {
              Swal.fire({
                text: "An error occured with your request.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          } else {
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        });
      };
      return {
        formData,
        rules,
        formRef,
        loading,
        passwordModalRef,
        submit,
        confirmPassword,
      };
    },
  });
